<template>
  <b-card no-body>
    <b-row class="px-2">
      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center flex-wrap justify-content-start mb-1 mb-md-0 mt-1"
      >
        <label>Entrées</label>

        <v-select
          v-model="state.perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block ml-50 mr-1"
        />

        <b-button variant="primary" :to="{ name: 'gsMyStocks' }">
          <feather-icon icon="ShoppingCartIcon" class="" />
          Vendre un produit
        </b-button>
      </b-col>

      <!-- Search -->

      <b-col cols="12" md="6" class="mt-1">
        <div class="d-flex align-items-center justify-content-end">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="state.filtre"
              class="d-inline-block mr-1"
              placeholder="Rechercher par : code de vente, type, prix, date..."
            />
          </b-input-group>
        </div>
      </b-col>
    </b-row>

    <q-loader-table
      :success="state.success"
      :empty="state.empty"
      :warring="state.warring"
    />

    <b-table
      hover
      responsive
      primary-key="id"
      :per-page="state.perPage"
      :current-page="state.currentPage"
      :items="sales"
      :fields="tableColumns"
      :filter="state.filter"
      show-empty
      empty-text="Aucun article enregistré"
      class="bg-white text-left"
      v-if="state.success === true"
    >
      <template #cell(code_facture)="data">
        <div>
          <span> {{ data.item.code_facture }} </span>
        </div>
      </template>

      <template #cell(prix_vente)="data">
        <div>
          <span> {{ formatter(data.item.prix_vente) }} </span>
        </div>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap py-1">
          <feather-icon
            v-b-modal.e-edit-seller
            @click="editSellerData(data.item)"
            icon="Edit3Icon"
            size="16"
            class="cursor-pointer"
          />
        </div>
      </template>
    </b-table>

    <!-- Les boutons de pagination -->

    <div class="mx-2 mb-2 pb-1">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"></span>
        </b-col>

        <!-- Pagination -->

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="state.currentPage"
            :total-rows="sales.length"
            :per-page="state.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <!-- Modal: Modifier vente 
    -
    -
    --
    -
    -
    -
    -
    -
    -
    -
    -
    -
    -
    -
    -->
    <b-modal
      :id="'e-edit-seller'"
      ok-only
      centered
      title="Modifier une nouvelle vente"
      @ok="updateSale"
    >
      <b-form>
        <!-- Libellé -->
        <b-form-group label="Libellé">
          <template #label>
            Pour quel client ? <span class="text-danger">*</span>
          </template>

          <v-select
            v-model="editSeller.client"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="nom"
            :options="clients"
            placeholder="Choisir un client"
          />
          <template v-slot:option="option">
            {{ option.nom }} {{ option.prenoms }}
          </template>
          <span
            class="text-danger"
            style="font-size: 12px"
            v-if="errorInput.path === 'libelle'"
          >
            {{ errorInput.message }}
          </span>
        </b-form-group>

        <!-- Prix de vente -->
        <b-form-group label="Prix de vente">
          <template #label> Prix de vente <span class="text-danger">*</span> </template>

          <b-form-input
            id="libelle"
            v-model="editSeller.prix_vente"
            name="libelle"
            placeholder="Prix de vente"
            type="number"
            min="0"
          />
          <span
            class="text-danger"
            style="font-size: 12px"
            v-if="errorInput.path === 'libelle'"
          >
            {{ errorInput.message }}
          </span>
        </b-form-group>

        <!-- Prix de vente -->
        <b-form-group label="Quantité">
          <template #label> Quantité <span class="text-danger">*</span> </template>

          <b-form-input
            id="libelle"
            v-model="editSeller.qte"
            name="libelle"
            placeholder="Quantité de vente"
            type="number"
            min="0"
          />
          <span
            class="text-danger"
            style="font-size: 12px"
            v-if="errorInput.path === 'libelle'"
          >
            {{ errorInput.message }}
          </span>
        </b-form-group>

        <b-form-group label="Libellé">
          <template #label> Appliquer une taxe </template>

          <v-select
            v-model="editSeller.taxe"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="libelle"
            :options="taxes"
            placeholder="Choisir une taxe pour l'article"
          />
          <!-- <template v-slot:option="option">
            {{ option.nom }} {{ option.prenoms }}
          </template> -->
          <span
            class="text-danger"
            style="font-size: 12px"
            v-if="errorInput.path === 'libelle'"
          >
            {{ errorInput.message }}
          </span>
        </b-form-group>
      </b-form>

      <template #modal-footer="{}">
        <b-button
          :disabled="state.loading === true ? true : false"
          variant="primary"
          @click.prevent="updateSale"
        >
          <span v-if="state.loading === false">Modifier</span>
          <b-spinner v-if="state.loading === true" label="Spinning"></b-spinner>
        </b-button>
      </template>
    </b-modal>

    {{ __OnRealTime }}
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BModal,
  VBModal,
  BForm,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import axios from "axios";
import { onMounted, reactive, ref, computed } from "@vue/composition-api";
import QLoaderTable from "@/components/__partials/loaders/qLoaderTable.vue";
import Article from "../dashboard/article/article.vue";
import vSelect from "vue-select";
import URL from "../request";
import moment from "moment";
import qDeviseUtils from "@/utils/qDeviseUtils";
import { qDecrypted } from "@/services/qeCrypt";

export default {
  components: {
    BInputGroupPrepend,
    BRow,
    BImg,
    BCol,
    BLink,
    BModal,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    VBModal,
    QLoaderTable,
    vSelect,
    Article,
  },
  setup(props, { root }) {
    // const providerArticle = Article.setup(props, { root });
    const sales = ref([]);
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: "",
      success: false,
      empty: false,
      warring: false,
      loading: false,
    });
    const perPageOptions = ["30"];
    const GIVE = ref(false);
    const tableColumns = reactive([
      {
        key: "code_facture",
        label: "N de vente",
      },
      {
        key: "prix_vente",
        label: "Prix de vente",
      },
      {
        key: "qte_un_article",
        label: "Quantité",
      },
      {
        key: "prix_total",
        label: "Prix total",
      },
      {
        key: "created_at",
        label: "Date de création",
      },
      {
        key: "actions",
        label: "Actions",
      },
    ]);

    const editSeller = reactive({
      client: "",
      prix_vente: "",
      qte: "",
      taxe: "",
    });

    const clients = ref([]);
    const taxes = ref([]);
    const errorInput = {
      path: "",
      message: "",
    };

    onMounted(async () => {
      await listSales();
      await getClients();
      await getTaxes();
    });
    const __OnRealTime = computed(() => {
      if (GIVE.value === true) {
        sales.value.length === 0
          ? ((state.empty = true), (state.success = false))
          : ((state.success = true), (state.empty = false));
      }
    });

    const editSellerData = (data) => {
      console.log(data);
      const __client = clients.value.filter((client) => data.client_id === client.id);
      console.log(__client);
      editSeller.client = __client;
      editSeller.prix_vente = data.prix_vente;
      editSeller.taxe = taxes.value.filter((taxe) => data.taxe_id === taxe.id);
      editSeller.qte = data.qte_un_article;
    };

    const listSales = async () => {
      try {
        const { data } = await axios.get(URL.INVENTORY_LIST);
        const _sales = [];
        if (data.pivot) {
          GIVE.value = true;
          for (let i = 0; i < data.pivot.length; i++) {
            const sale = data.pivot[i];
            sale.created_at = moment(sale.created_at).format("DD-MM-YYYY");
            sale.prix_total = formatter(parseInt(sale.prix_total));
            sale.code_facture = `${sale.code_facture}`.replace("FAC", "VEN");
            _sales.push(sale);
          }

          sales.value = _sales;
          console.log(sales.value);
        }
      } catch (error) {
        state.success = false;
        state.warring = true;
        console.log(error);
      }
    };

    const updateSale = async () => {
      try {
        state.loading = true;
        const { data } = await axios.post(URL.SELLER_EDIT, {
          id: editSellerData.value.id,
          client: editSeller.client.id,
          article_id: [sellerData.value.id],
          prix_vente: [editSeller.prix_vente],
          qte: [editSeller.qte],
          prix_total: [editSeller.prix_vente * editSeller.qte],
          taxe_id: editSeller.taxe.id,
        });
        if (data) {
          state.loading = false;
        }
      } catch (error) {
        state.loading = false;
        console.log(error);
      }
    };

    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

    /**
     * @param {*} e
     */
    const getClients = async () => {
      try {
        const { data } = await axios.get(URL.CLIENT_LIST);
        console.log(data);
        if (data[0]) {
          clients.value = data[0];
        }
      } catch (error) {
        console.log(error);
      }
    };

    /**
     * @param {*} e
     */
    const getTaxes = async () => {
      try {
        const { data } = await axios.get(URL.TAXE_LIST);
        console.log(data);
        if (data.taxes) {
          taxes.value = data.taxes;
        }
      } catch (error) {
        console.log(error);
      }
    };

    return {
      state,
      sales,
      taxes,
      clients,
      editSeller,
      tableColumns,
      perPageOptions,
      __OnRealTime,
      editSellerData,
      updateSale,
      errorInput,
      formatter,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
